import React from "react"
import { Label } from "@fluentui/react/lib/Label"
import { Stack } from "@fluentui/react/lib/Stack"
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner"

export default function Loading(props) {
    const rowProps = { horizontal: true, verticalAlign: "center" }
    const tokens = {
        spinnerStack: {
            childrenGap: 20,
        },
    }
    return (
        <div style={{ margin: "auto" }}>
            <Stack {...rowProps} tokens={tokens.spinnerStack}>
                <Label>{props.message}</Label>
                <Spinner size={SpinnerSize.large} />
            </Stack>
        </div>
    )
}
