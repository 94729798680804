const themes = {
    light: {
        themePrimary: "#96DCFF",
        themeLighterAlt: "#fbfeff",
        themeLighter: "#eef9ff",
        themeLight: "#e0f5ff",
        themeTertiary: "#c0eaff",
        themeSecondary: "#a3e0ff",
        themeDarkAlt: "#87c6e6",
        themeDark: "#72a7c2",
        themeDarker: "#547b8f",

        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#a19f9d",
        neutralSecondary: "#323130",
        neutralPrimaryAlt: "#3b3a39",
        neutralPrimary: "#323130",
        neutralDark: "#201f1e",
        black: "#000000",
        white: "#ffffff",
    },
    dark: {
        themePrimary: "#96dcff",

        themeLighterAlt: "#87c6e6",
        themeLighter: "#547b8f",
        themeLight: "#72a7c2",
        themeTertiary: "#c0eaff",
        themeSecondary: "#a3e0ff",
        themeDarkAlt: "#fbfeff",
        themeDark: "#e0f5ff",
        themeDarker: "#eef9ff",

        neutralLighterAlt: "#323130",
        neutralLighter: "#4a4847",
        neutralLight: "#2f2e2d",
        neutralQuaternaryAlt: "#2c2b2a",
        neutralQuaternary: "#2a2928",
        neutralTertiaryAlt: "#4a4946",
        neutralTertiary: "#c8c8c8",
        neutralSecondary: "#ffffff",
        neutralPrimaryAlt: "#dadada",
        neutralPrimary: "#ffffff",
        neutralDark: "#f4f4f4",
        black: "#f8f8f8",
        white: "#323130",
    },
}

export default themes
