import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import config from "../config"

export default function Auth0ProdiverProspecer(props) {
    return (
        <Auth0Provider
            domain={config.auth0.domain}
            clientId={config.auth0.clientId}
            redirectUri={config.auth0.redirectUri}
            audience={config.auth0.audience}
            scope={config.auth0.scopes}
            useRefreshTokens={true}
            cacheLocation="localstorage"
        >
            {props.children}
        </Auth0Provider>
    )
}
