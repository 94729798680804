export function valueInKeyPath(keypath, obj) {
    try {
        if (typeof keypath === "symbol") {
            return obj[keypath]
        }
        return keypath
            .split(".")
            .reduce((previous, current) => previous[current], obj)
    } catch (err) {
        throw Error("Could not result " + keypath + " in object")
    }
}

export function setValueInKeyPath(keypath, obj, v) {
    const parts = keypath.split(".")

    let value = keypath.split(".").reduce((previous, current, index) => {
        if (parts.length - 1 === index) {
            return previous
        } else {
            return previous[current]
        }
    }, obj)

    if (value === null) {
        value = obj
    }

    value[parts[parts.length - 1]] = v
    return obj
}

export function deleteKeyPath(keypath, obj) {
    const parts = keypath.split(".")

    let value = keypath.split(".").reduce((previous, current, index) => {
        if (parts.length - 1 === index) {
            delete obj[keypath]
        } else {
            delete previous[current]
        }
    }, obj)

    return obj
}
