import { Stack } from "@fluentui/react"
import { TextField, MaskedTextField } from "@fluentui/react/lib/TextField"
import React, { useState } from "react"
export default function Phone(props) {
    let number = ""
    let ext = ""

    if (props.value && props.value.includes(" ext:")) {
        const parts = props.value.split(" ext:")
        number = parts[0]
        ext = parts[1]
    } else {
        number = props.value
        ext = ""
    }
    function valueFunc(number, ext) {
        let num = number
        if (ext) {
            num = num + " ext:" + ext
        }
        return num
    }

    return (
        <>
            {["US", "CA"].includes(props.coupledValue) ? (
                <Stack horizontal>
                    <Stack.Item grow={2}>
                        <MaskedTextField
                            mask="(999) 999-9999"
                            {...props}
                            value={number ? number : ""}
                            onChange={(e, value) => {
                                if (value === "(___) ___-____") {
                                    return
                                } else {
                                    props.onChange(e, valueFunc(value, ext))
                                }
                            }}
                        />
                    </Stack.Item>

                    {props.extension !== false && (
                        <Stack.Item grow={1}>
                            <MaskedTextField
                                styles={{ root: { width: "120px" } }}
                                onChange={(e, value) => {
                                    const v = value.replaceAll("_", "")
                                    props.onChange(e, valueFunc(number, v))
                                }}
                                value={ext}
                                prefix="ext"
                                label="Ext"
                                mask="99999"
                            />
                        </Stack.Item>
                    )}
                </Stack>
            ) : (
                <TextField {...props} />
            )}
        </>
    )
}
