import { TextField } from "@fluentui/react/lib/TextField"
import React, { useState } from "react"
export default function Password(props) {
    const [passes, setPasses] = useState({ first: "", second: "" })
    const [error, setError] = useState("")
    function update(e, passes) {
        if (passes.first === "" && passes.second === "") {
            setError("")
            props.onChange(e, null)
            return
        }
        if (passes.first !== passes.second) {
            setError("passwords must match")
            return
        } else {
            props.onChange(e, passes.first)
            setError("")
        }
    }
    return (
        <>
            <TextField
                {...props}
                value={passes.first}
                onChange={(e, value) => {
                    setPasses({ ...passes, first: value })
                    update(e, { ...passes, first: value })
                }}
            />

            <TextField
                {...props}
                label={props.label + " Again"}
                value={passes.second}
                errorMessage={error}
                onChange={(e, value) => {
                    setPasses({ ...passes, second: value })
                    update(e, { ...passes, second: value })
                }}
            />
        </>
    )
}
