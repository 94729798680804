import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import Loading from "../layouts/Loading"

export default function Protected(props) {
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

    if (isLoading) {
        return (
            <Loading message="Veryfing your access. Hang tight (security and all)" />
        )
    }

    if (!isAuthenticated) {
        loginWithRedirect()
    }

    if (isAuthenticated) {
        return props.children
    } else {
        return (
            <h1
                style={{
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10vw",
                }}
            >
                redirecting to login
            </h1>
        )
    }
}
